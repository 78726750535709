import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../hooks/AuthProvider';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 300px;
  }
`;

const LoginInput = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 0.75rem;
  }
`;

const LoginButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
  }
`;

const StatusMessage = styled.p`
  margin-top: 1rem;
  text-align: center;
  color: ${(props) => (props.className === 'error' ? '#e74c3c' : '#27ae60')};
`;

const ForgotPasswordButton = styled.button`
  background: none;
  border: none;
  color: #27ae60;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0.5rem;
    margin-top: 0.75rem;
  }
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [statusMessage, setStatusMessage] = useState({ text: '', isError: false });
  const { handleLogin } = useContext(AuthContext); // Access AuthContext
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage({ text: '', isError: false });

    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDoc = await getDoc(doc(db, 'Users', user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();

        const userInfo = {
          uid: user.uid,
          email: user.email,
          role: userData.type,
        };

        handleLogin(userInfo); // Use handleLogin to update global state

        switch (userData.type) {
          case 'manager':
            navigate('/manager-dashboard');
            break;
          case 'supervisor':
            navigate('/supervisor-dashboard');
            break;
          case 'spray_tech':
            navigate('/spray-tech-dashboard');
            break;
          case 'admin':
            navigate('/admin-dash');
            break;
          default:
            navigate('/home');
            break;
        }
      } else {
        setStatusMessage({
          text: 'User data not found. Please contact support.',
          isError: true,
        });
      }
    } catch (error) {
      console.error('Login error:', error);
      const errorMessages = {
        'auth/user-not-found': 'No user found with this email. Please check your email or sign up.',
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/invalid-email': 'Invalid email address. Please enter a valid email.',
        'auth/user-disabled': 'This account has been disabled. Please contact support.',
      };
      setStatusMessage({
        text: errorMessages[error.code] || 'An error occurred during login. Please try again later.',
        isError: true,
      });
    }
  };

  return (
    <LoginContainer>
      <h2>{t('Login')}</h2>
      <LoginForm onSubmit={handleSubmit}>
        <LoginInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <LoginInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <LoginButton type="submit">{t('Login')}</LoginButton>
      </LoginForm>
      {statusMessage.text && (
        <StatusMessage className={statusMessage.isError ? 'error' : 'success'}>
          {statusMessage.text}
        </StatusMessage>
      )}
      <ForgotPasswordButton onClick={() => navigate('/forgot-password')}>
        {t('Forgot Password?')}
      </ForgotPasswordButton>
    </LoginContainer>
  );
};

export default Login;
