import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, AuthContext } from '../src/components/hooks/AuthProvider';
import styled, { createGlobalStyle } from 'styled-components';
import SignUp from './components/auth/SignUp';
import Login from './components/auth/Login';
import Header from './components/dashboards/Header';
import AdminDashboard from './components/dashboards/AdminDashboard';
import SupervisorDashboard from './components/dashboards/SupervisorDash';
import SprayTechDashboard from './components/dashboards/SprayTechDash';
import ChemAppForm from './components/Services Logic/ChemAppForm';
import JobForm from './components/Services Logic/JobForm';
import AdminJobList from './components/Services Logic/AdminJobList';
import JobList from './components/Services Logic/Job List/JobList';
import ManagerDashboard from './components/dashboards/ManagerDash9';
import JobDetails from './components/Services Logic/JobDetails';
import ErrorBoundary from './components/Helper Functions/ErrorBoundary';
import SupervisorServiceList from './components/Services Logic/SupervisorServicesList';
import ManagerJobForm from './components/Services Logic/ManagerJob.Form';
import Notes from './components/Notes/Notes';
import TaskList from './components/TaskList';
import './i18n/i18n';

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
`;

const Footer = styled.footer`
  background-color: #296b1a;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

// ProtectedRoute component definition
const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser, loading } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <p>Loading...</p>; // Or a loading spinner
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && (!currentUser.role || !allowedRoles.includes(currentUser.role))) {
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};


const App = () => {
  return (
    <Router>
      <ErrorBoundary>
        <AuthProvider>
          <AppContainer>
            <GlobalStyle />
            <Header />
            <Routes>
              <Route path="/" element={<Navigate to="/login" replace />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<SignUp />} />

              <Route
                path="/spray-tech-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['spray_tech', 'admin']}>
                    <SprayTechDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/chem-app-form"
                element={
                  <ProtectedRoute allowedRoles={['spray_tech', 'admin']}>
                    <ChemAppForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/job-form"
                element={
                  <ProtectedRoute allowedRoles={['admin', 'manager', 'supervisor']}>
                    <JobForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/job-list"
                element={
                  <ProtectedRoute allowedRoles={['admin', 'manager', 'supervisor', 'spray_tech']}>
                    <JobList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin-job-list"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <AdminJobList />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/jobs/:id"
                element={
                  <ProtectedRoute allowedRoles={['manager', 'supervisor', 'admin']}>
                    <JobDetails />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin-dash"
                element={
                  <ProtectedRoute allowedRoles={['admin']}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manager-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['manager', 'admin']}>
                    <ManagerDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manager-job-form"
                element={
                  <ProtectedRoute allowedRoles={['manager', 'admin']}>
                    <ManagerJobForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/supervisor-dashboard"
                element={
                  <ProtectedRoute allowedRoles={['supervisor', 'admin']}>
                    <SupervisorDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/supervisor-job-list"
                element={
                  <ProtectedRoute allowedRoles={['supervisor', 'admin']}>
                    <SupervisorServiceList />
                  </ProtectedRoute>
                }
              />

              <Route path="/notes" element={<Notes />} />

              <Route
                path="/tasks"
                element={
                  <ProtectedRoute allowedRoles={['admin', 'manager']}>
                    <TaskList />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AppContainer>
        </AuthProvider>
      </ErrorBoundary>
    </Router>
  );
};

export default App;
