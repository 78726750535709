import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios'; // Import Axios
import './Notes.css';
import csvData from '../../customer_data.json'; // Your JSON data file

const Notes = () => {
    const [noteText, setNoteText] = useState('');
    const [email, setEmail] = useState('');
    const [noteDate, setNoteDate] = useState(''); // State for the date field
    const [customerName, setCustomerName] = useState('');
    const [customerID, setCustomerID] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [status, setStatus] = useState({ message: '', type: '' });
    const [isSaving, setIsSaving] = useState(false);

    // Quickbase credentials
    const QB_REALM = process.env.REACT_APP_QB_REALM_HOSTNAME;
    const QB_TABLE_ID = 'btgrcatjx';
    const QB_AUTH_TOKEN = `QB-USER-TOKEN ${process.env.REACT_APP_QB_API_KEY}`;

    // Axios instance for Quickbase
    const quickbaseApi = axios.create({
        baseURL: 'https://api.quickbase.com/v1',
        headers: {
            'QB-Realm-Hostname': QB_REALM,
            'Authorization': QB_AUTH_TOKEN,
            'Content-Type': 'application/json'
        }
    });

    // Display a status message
    const showStatus = (message, isSuccess) => {
        setStatus({ message, type: isSuccess ? 'success' : 'error' });
        setTimeout(() => setStatus({ message: '', type: '' }), 3000);
    };

    // Save note to Quickbase
    const saveNote = async () => {
        if (!noteText.trim()) {
            showStatus('Please enter a note', false);
            return;
        }
    
        if (!email.trim()) {
            showStatus('Please enter your email', false);
            return;
        }
    
        if (!customerName.trim() || !customerID) {
            showStatus('Please select a valid customer', false);
            return;
        }
    
        if (!noteDate.trim()) {
            showStatus('Please select a date', false);
            return;
        }
    
        // Convert the selected date to ISO 8601 format
        const formattedDate = new Date(noteDate).toISOString();
    
        setIsSaving(true);
    
        try {
            const payload = {
                to: QB_TABLE_ID,
                data: [
                    {
                        "8": { value: noteText },
                        "47": { value: email },
                        "9": { value: customerID },
                        "13": { value: formattedDate } // Format date for Quickbase
                    }
                ]
            };
    
            const response = await quickbaseApi.post('/records', payload);
    
            console.log('QuickBase Response:', response.data);
    
            showStatus('Note saved successfully!', true);
            setNoteText('');
            setEmail('');
            setCustomerName('');
            setCustomerID('');
            setNoteDate('');
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
            showStatus(`Failed to save note: ${errorMessage}`, false);
        } finally {
            setIsSaving(false);
        }
    };
    
    

    // Get suggestions based on input
    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
            ? []
            : csvData.filter(customer =>
                customer.Customer.toLowerCase().includes(inputValue)
            );
    };

    // Autosuggest handlers
    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onSuggestionSelected = (event, { suggestion }) => {
        console.log('Selected Suggestion:', suggestion); // Debug
        setCustomerName(suggestion.Customer); // Set customer name
        setCustomerID(suggestion["Record ID#"]); // Set customer Record ID#
    };

    const onChange = (event, { newValue }) => {
        setCustomerName(newValue);
    };
    const theme = {
        container: 'react-autosuggest__container',
        input: 'react-autosuggest__input',
        suggestionsContainer: 'react-autosuggest__suggestions-container',
        suggestionsList: 'react-autosuggest__suggestions-list',
        suggestion: 'react-autosuggest__suggestion',
        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    };
    

    const inputProps = {
        placeholder: 'Type a customer name',
        value: customerName,
        onChange,
    };

    return (
        <div className="notes-container">
            <h1>Quick Notes</h1>
            <textarea
                value={noteText}
                onChange={(e) => setNoteText(e.target.value)}
                placeholder="Type your note here..."
                className="notes-textarea"
                aria-label="Note Content"
            />
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Type your email here..."
                className="notes-email"
                aria-label="Email Address"
                required
            />
            <input
                type="date"
                value={noteDate}
                onChange={(e) => setNoteDate(e.target.value)}
                className="notes-date"
                aria-label="Note Date"
                required
            />
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion) => suggestion.Customer}
                renderSuggestion={(suggestion) => <div>{suggestion.Customer}</div>}
                inputProps={inputProps}
                onSuggestionSelected={onSuggestionSelected}
                theme={theme}
/>

            <button
                onClick={saveNote}
                disabled={isSaving}
                className="notes-button"
                aria-busy={isSaving}
            >
                {isSaving ? 'Saving...' : 'Save Note'}
            </button>
            {status.message && (
                <div className={`status-message ${status.type}`}>
                    {status.message}
                </div>
            )}
        </div>
    );
};

export default Notes;