import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { ChemicalApplicationPDF } from '../PDF Functions/ChemAppPDF';
import QuickbaseUpdate from '../Quickbase Functions/QBChemForm';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
  text-align: center;
`;


const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 1rem;
`;

const Checkbox = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0.75rem;
    font-size: 16px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem; /* Add space between buttons */
  margin-top: 1rem; /* Space above the buttons */
`;

const CloseButton = styled(Button)`
  background-color: #e74c3c; /* Red background for better visibility */
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.5rem 1rem;
`;

const PDFContainer = styled.div`
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: 80%;
  background: white;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  overflow: hidden;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.div`
  color: #27ae60;
  margin-bottom: 1rem;
`;

const Select = styled.select`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TechnicianDashboard = () => {
  const navigate = useNavigate();
  const [newRecord, setNewRecord] = useState({
    applicationDate: '',
    applicator: '',
    applicatorId: '',
    project: '',
    address: '',
    city: '',
    state: '',
    temperature: '',
    wind: '',
    chemicals: [
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
      { category: '', product: '', epaNumber: '', lotNumber: '', rate: '', per: '', totalApplied: '', totalConc: '' },
    ],
    checkboxes: {
      turfFertilizer: false,
      turfPostEmerge: false,
      turfPreEmerge: false,
      turfInsect: false,
      antControl: false,
      bedFertilizer: false,
      bedPostEmerge: false,
      bedPreEmerge: false,
      tsInsect: false,
      ccPostEmerge: false,
      tsDisease: false,
      tsFertilizer: false,
      palmFertilizer: false,
      palmFungicide: false,
    },
    notes: '',
  });

  const [showPDF, setShowPDF] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [successModalVisible, setSuccessModalVisible] = useState(false);

  

  const chemicals = {
    Antitranspirants: ['Trans Film - label', 'Trans Film - SDS'],
    Fungicides: [
      'Dithane 75 DF RAINSHIELD - label',
      'Dithane 75 DF RAINSHIELD - SDS',
      'Eagle 20 EW (myclobutanil)- label',
      'Eagle 20 EW (myclobutanil) - SDS',
      'Headway (axoxystrobin) -label',
      'Heritage (azoxystrobin) -label',
      'Lesco Mancozeb 4F (mancozeb) - label',
      'Mefenoxam 2 AQ (mefenoxam) label',
      'Mefenoxam 2 AQ (mefenoxam) - label',
      'Myclobutanil 20 EW - label',
      'Myclobutanil 20 EW - SDS',
      'Pedigree SC (flutolanil) -label',
      'Pedigree SC (flutolanil) - SDS',
      'Propiconazole 14.3 (propiconazole) - label',
      'Propiconazole 14.3 (propiconazole) - SDS',
      'Strobe 2 L (axoystrobin) - label',
      'Subdue Maxx (mefenoxam) -label',
      'Transom 4.5 F (thiophanate-methyl) - label',
      'Transom 4.5 F (thiophanate-methyl) -SDS',
      'T-Storm F (thiophanate-methyl) - label',
      'T-Store F (thiophanate-methyl) -SDS',
      'Turfcide 10 G (PCNB) -SDS',
      'Turfcide 10 G (PCNB) -label',
      'Xzemplar (fluxapyroxad) - label',
      'Xzemplar (fluxapyroxad) - SDS',
    ],
    GrowthRegulators: [
      'Pac-Low (paclobutrazole) - label',
      'Tide Paclo 2 SC (paclobutrazole) - label',
      'Pac-Low (paclobutrazole) -label',
      'Pac-Low (pacloburtrazole) - label',
      'Tide Paclo 2 SC (paclobutrazole) - label (a)',
      'Tide Paclo 2 SC (paclobutrazole) -SDS',
      'Tide Paclo 2 SC (paclobutrazole) - label',
      'Trim Tect (paclobutrazole) - label',
      'Trim Tect (paclobutrazole) - SDS',
    ],
    Herbicides: [
      'Avenue South (4-Way) - label',
      'Barricade 4FL (prodiamine) - label',
      'Blindside (sulfentrazone) - label',
      'Celcius WG (indosulfuron) - label (a)',
      'Certainty (sulfosulfuron) - label',
      'Drive XLR8 (auinclorac) - label',
      'Fusilade II T&O (fluazifop) - label',
      'Lesco Three-Way (3 Way) - label',
      'MSM Turf (metsulfuron) - label',
      'Speed Zone Southern (4-Way) - label',
    ],
    Insecticides: [
      'Acelepryn (chlorantraniliprole) - label',
      'Acephate 90 Prills (acephate) - label',
      'Addvion Fire Ant Bait (indoxibarb) - label',
      'Arena 50 WDG(clothianidin) - label',
      'Cross Check Plus (bifenthrin) - label',
    ],
    Surfactants: ['Lesco Methylated Seed Oil - label', 'Lesco Methylated Seed Oil - SDS'],
  };

  const handleChemicalChange = (index, e) => {
    const { name, value } = e.target;
    setNewRecord((prev) => {
      const updatedChemicals = [...prev.chemicals];
      updatedChemicals[index][name] = value;

      // Reset product if category changes
      if (name === 'category') {
        updatedChemicals[index].product = '';
      }

      return { ...prev, chemicals: updatedChemicals };
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRecord((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const generatePdfBlob = async () => {
    return await pdf(<ChemicalApplicationPDF recordData={newRecord} />).toBlob();
  };

  // Converts a Blob to Base64
  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract Base64 string
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };


  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewRecord((prev) => {
      const updatedCheckboxes = {
        ...prev.checkboxes,
        [name]: checked,
      };
      console.log('Updated Checkboxes:', updatedCheckboxes); // Debug log
      return {
        ...prev,
        checkboxes: updatedCheckboxes,
      };
    });
  };

  const handlePDFPreview = async () => {
    await generatePdfBlob(); // Ensure the Blob is generated
    setShowPDF(true);
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const blob = await generatePdfBlob();
      const pdfBase64 = await convertBlobToBase64(blob);

      // Prepare data for Quickbase
      const fields = {
        applicator: newRecord.applicator,
        projectName: newRecord.project,
        applicationDate: newRecord.applicationDate,
        notes: newRecord.notes,
        pdfUrl: pdfBase64, // PDF in Base64 format
      };

      // Call Quickbase update function
      const result = await QuickbaseUpdate({
        tableId: 'brt8jnu22', // Replace with your actual table ID
        fields,
      });

      console.log('Quickbase Update Success:', result);
      setSuccessModalVisible(true);
    } catch (error) {
      console.error('Error during submission:', error);
      alert('An error occurred. Please try again.');
    }
  };



  const handlePDFClose = () => {
    setShowPDF(false);
  };

const handleCloseModal = () => {
  setSuccessModalVisible(false);
  navigate('/spray-tech-dashboard');
};


  const checkboxLabels = [
    { name: 'turfFertilizer', label: 'Turf Fertilizer' },
    { name: 'turfPostEmerge', label: 'Turf Post-Emerge' },
    { name: 'turfPreEmerge', label: 'Turf Pre-Emerge' },
    { name: 'turfInsect', label: 'Turf Insect' },
    { name: 'antControl', label: 'Ant Control' },
    { name: 'bedFertilizer', label: 'Bed Fertilizer' },
    { name: 'bedPostEmerge', label: 'Bed Post-Emerge' },
    { name: 'bedPreEmerge', label: 'Bed Pre-Emerge' },
    { name: 'tsInsect', label: 'T/S Insect' },
    { name: 'ccPostEmerge', label: 'C/C Post Emerge' },
    { name: 'tsDisease', label: 'T/S Disease' },
    { name: 'tsFertilizer', label: 'T/S Fertilizer' },
    { name: 'palmFertilizer', label: 'Palm Fertilizer' },
    { name: 'palmFungicide', label: 'Palm Fungicide' },
  ];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {/* Address, City, and State Section */}
      <h2>Location</h2>
      <Input
        name="address"
        placeholder="Address"
        value={newRecord.address}
        onChange={handleInputChange}
      />
      <Input
        name="city"
        placeholder="City"
        value={newRecord.city}
        onChange={handleInputChange}
      />
      <Input
        name="state"
        placeholder="State"
        value={newRecord.state}
        onChange={handleInputChange}
      />
        <h3>Chemical Application Details</h3>
        <Input
          name="applicator"
          placeholder="Applicator Name"
          value={newRecord.applicator}
          onChange={handleInputChange}
        />
        <Input
          name="applicatorId"
          placeholder="Applicator ID"
          value={newRecord.applicatorId}
          onChange={handleInputChange}
        />
        <Input
          name="project"
          placeholder="Project Name"
          value={newRecord.project}
          onChange={handleInputChange}
        />
        <Input
          name="applicationDate"
          type="date"
          value={newRecord.applicationDate}
          onChange={handleInputChange}
        />
        <Input
          name="temperature"
          placeholder="Temperature (°F)"
          value={newRecord.temperature}
          onChange={handleInputChange}
        />
        <Input
          name="wind"
          placeholder="Wind (mph)"
          value={newRecord.wind}
          onChange={handleInputChange}
        />
        <CheckboxContainer>
          {checkboxLabels.map((checkbox) => (
            <Checkbox key={checkbox.name}>
              <input
                type="checkbox"
                name={checkbox.name}
                checked={newRecord.checkboxes[checkbox.name]}
                onChange={handleCheckboxChange}
              />
              {checkbox.label}
            </Checkbox>
          ))}
        </CheckboxContainer>
        {newRecord.chemicals.map((chemical, index) => (
          <div key={index}>
            <h3>Chemical {index + 1}</h3>
            <Select
              name="category"
              value={chemical.category}
              onChange={(e) => handleChemicalChange(index, e)}
            >
              <option value="">Select Category</option>
              {Object.keys(chemicals).map((cat) => (
                <option key={cat} value={cat}>
                  {cat}
                </option>
              ))}
            </Select>
            <Select
              name="product"
              value={chemical.product}
              onChange={(e) => handleChemicalChange(index, e)}
              disabled={!chemical.category}
            >
              <option value="">Select Product</option>
              {chemical.category &&
                chemicals[chemical.category]?.map((product) => (
                  <option key={product} value={product}>
                    {product}
                  </option>
                ))}
            </Select>
            <Grid>
              <Input
                name="epaNumber"
                placeholder="EPA Number"
                value={chemical.epaNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="lotNumber"
                placeholder="Lot Number"
                value={chemical.lotNumber}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="rate"
                placeholder="Rate"
                value={chemical.rate}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="per"
                placeholder="Per"
                value={chemical.per}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalApplied"
                placeholder="Total Applied"
                value={chemical.totalApplied}
                onChange={(e) => handleChemicalChange(index, e)}
              />
              <Input
                name="totalConc"
                placeholder="Total Concentration"
                value={chemical.totalConc}
                onChange={(e) => handleChemicalChange(index, e)}
              />
            </Grid>
          </div>
        ))}
        <Input
          name="notes"
          placeholder="Notes"
          value={newRecord.notes}
          onChange={handleInputChange}
        />
        <ButtonContainer>
        <Button type="button" onClick={handlePDFPreview}>
          Preview PDF
        </Button>
        <Button type="submit">Submit</Button>
      </ButtonContainer>
    </Form>
    {showPDF && (
        <PDFContainer>
          <PDFViewer width="100%" height="100%">
            <ChemicalApplicationPDF recordData={newRecord} />
          </PDFViewer>
          <CloseButton onClick={handlePDFClose}>Close</CloseButton>
        </PDFContainer>
      )}
      {successModalVisible && (
        <ModalOverlay>
          <ModalContent>
            <h2>Submission Successful!</h2>
            <p>Your record has been successfully updated.</p>
            <Button onClick={handleCloseModal}>Close</Button>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default TechnicianDashboard;