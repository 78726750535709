import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  padding: 2rem;
  background-color: #f9f9f9;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: #27ae60;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%;
  max-width: 250px;
  text-align: center;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #219653;
    transform: translateY(-2px);
  }
`;

const AdminDashboard = () => {
  const navigate = useNavigate();

  const dashboards = [
    { label: 'Spray Tech Dashboard', path: '/spray-tech-dashboard' },
    { label: 'Chemical Application Form', path: '/chem-app-form' },
    { label: 'Job Form', path: '/job-form' },
    { label: 'Job List', path: '/admin-job-list' },
    { label: 'Manager Dashboard', path: '/manager-dashboard' },
    { label: 'Supervisor Dashboard', path: '/supervisor-dashboard' },
    { label: 'Supervisor Job List', path: '/supervisor-job-list' },
    { label: 'Manager Job Form', path: '/manager-job-form' },
  ];

  return (
    <Container>
      <StyledTitle>Admin Dashboard</StyledTitle>
      <ButtonContainer>
        {dashboards.map((dashboard) => (
          <StyledButton
            key={dashboard.path}
            onClick={() => navigate(dashboard.path)}
          >
            {dashboard.label}
          </StyledButton>
        ))}
      </ButtonContainer>
    </Container>
  );
};

export default AdminDashboard;
