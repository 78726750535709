import styled from 'styled-components';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../hooks/AuthProvider'; // Use AuthProvider
import { FaThermometerHalf, FaWind, FaQuestionCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// Styled Components
const HeaderContainer = styled.header`
  background-color: #296b1a;
  color: white;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;

  @media (max-width: 768px) {
    padding: 0.5rem;
    flex-direction: column;
    min-height: auto;
  }
`;

const LogoTitleContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

const Logo = styled.img`
  height: 40px;

  @media (max-width: 768px) {
    height: 30px;
    margin-bottom: 0.5rem;
  }
`;

const HeaderTitle = styled.h1`
  margin: 0 0 0 1rem;
  font-size: 1.75rem;
  font-weight: 700;
  color: #f1f1f1;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 3px;
    background-color: #e74c3c;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin-top: 0.5rem;
  }
`;

const HeaderButton = styled.button`
  background-color: #ffffff;
  color: #296b1a;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: #e8f5e9;
  }
`;

const IconButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ffffff;
  }

  svg {
    color: #ffffff;
    width: 24px;
    height: 24px;
    transition: color 0.3s ease;
  }

  &:hover svg {
    color: #296b1a;
  }
`;

const WeatherWidgetContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
`;

const WeatherData = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
  color: white;

  svg {
    color: white;
    font-size: 1.2em;
  }
`;

// Weather Widget Component
const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWeather = async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=${latitude},${longitude}`
        );
        const { current } = response.data;
        setWeather({
          temperature: current.temp_f,
          windSpeed: current.wind_mph,
          windDirection: current.wind_dir,
        });
      } catch (err) {
        setError('Unable to fetch weather data');
      } finally {
        setLoading(false);
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchWeather(latitude, longitude);
        },
        () => {
          setError('Location access is needed');
          setLoading(false);
        }
      );
    } else {
      setError('Geolocation not supported');
      setLoading(false);
    }
  }, []);

  if (loading) return <WeatherData>Loading...</WeatherData>;
  if (error) return <WeatherData>Error fetching weather</WeatherData>;
  if (!weather) return null;

  return (
    <WeatherWidgetContainer>
      <WeatherData aria-label={`Temperature: ${weather.temperature}°F`}>
        <FaThermometerHalf />
        {Math.round(weather.temperature)}°F
      </WeatherData>
      <WeatherData aria-label={`Wind: ${weather.windSpeed} mph ${weather.windDirection}`}>
        <FaWind />
        {weather.windSpeed} mph {weather.windDirection}
      </WeatherData>
    </WeatherWidgetContainer>
  );
};

// Header Component
const Header = () => {
  const { t, i18n } = useTranslation();
  const { currentUser, handleLogout } = useContext(AuthContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    handleLogout();
    navigate('/login');
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const getDashboardLink = () => {
    if (!currentUser || !currentUser.role) return '/';
    switch (currentUser.role.toLowerCase()) {
      case 'admin':
        return '/admin-dash';
      case 'manager':
        return '/manager-dashboard';
      case 'supervisor':
        return '/supervisor-dashboard';
      case 'spray_tech':
        return '/spray-tech-dashboard';
      default:
        return '/';
    }
  };

  const toggleModal = () => setModalOpen(!isModalOpen);

  return (
    <HeaderContainer>
      <LogoTitleContainer>
        <Link to={currentUser ? getDashboardLink() : '/'}>
          <Logo src={`${process.env.PUBLIC_URL}/rciLogo.png`} alt="RCI Logo" />
        </Link>
        <HeaderTitle>RCI Service NOW!</HeaderTitle>
      </LogoTitleContainer>
      <ButtonContainer>
        <WeatherWidget />
        <HeaderButton onClick={toggleLanguage}>
          {i18n.language === 'en' ? 'Español' : 'English'}
        </HeaderButton>
        {!currentUser ? (
          <Link to="/login">
            <HeaderButton>{t('Login')}</HeaderButton>
          </Link>
        ) : (
          <>
            <HeaderButton onClick={handleLogoutClick}>{t('Logout')}</HeaderButton>
            <IconButton onClick={toggleModal} aria-label="Request Assistance">
              <FaQuestionCircle />
            </IconButton>
          </>
        )}
      </ButtonContainer>
      {isModalOpen && <div>Modal Content Goes Here</div>}
    </HeaderContainer>
  );
};

export default Header;
