import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import PropertyList from './QB Lists/PropertyList';
import { collection, query, where, getDocs, addDoc, getDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from './Firebase Functions/firebase';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 1200px;
  max-height: 90%; /* Keep vertical scrolling if needed */
  overflow-y: visible; /* Allow suggestions to overflow */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  position: relative;
`;


const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;

  &:hover {
    color: #000;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.2rem;
  width: 100%;
`;

const SuggestionsContainer = styled.div`
  position: relative; /* Provides a context for the absolute positioning */
  z-index: 1040; /* Lower than SuggestionsList to allow overlap */
`;


const SuggestionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1050; /* Ensure it's above other elements */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;


const SuggestionItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Button = styled.button`
  padding: 1rem 2rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const AddButton = styled.button`
  padding: 0.5rem 1rem; /* Reduced size */
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem; /* Smaller font */

  display: flex; /* Flex for alignment */
  align-items: center; /* Center icon and text vertically */
  gap: 0.5rem; /* Spacing between icon and text */

  &:hover {
    background-color: #45a049; /* Slightly darker on hover */
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const AddTaskModal = ({ isOpen, onClose, user, onTaskAdded }) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskNote, setTaskNote] = useState('');
  const [supervisorInput, setSupervisorInput] = useState('');
  const [supervisorSuggestions, setSupervisorSuggestions] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [completionWeek, setCompletionWeek] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchSupervisors = async (searchTerm) => {
    if (!searchTerm.trim()) {
      setSupervisorSuggestions([]);
      return;
    }
    try {
      const q = query(collection(db, 'Users'), where('type', '==', 'supervisor'));
      const snapshot = await getDocs(q);

      const supervisors = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((supervisor) =>
          supervisor.first_name.toLowerCase().includes(searchTerm.toLowerCase())
        );

      setSupervisorSuggestions(supervisors);
    } catch (error) {
      console.error('Error fetching supervisors:', error);
    }
  };

  const handleSupervisorInputChange = (e) => {
    const searchTerm = e.target.value;
    setSupervisorInput(searchTerm);
    fetchSupervisors(searchTerm);
  };

  const handleSupervisorSelect = (supervisor) => {
    setSelectedSupervisor(supervisor);
    setSupervisorInput(`${supervisor.first_name} ${supervisor.last_name}`);
    setSupervisorSuggestions([]);
  };

  const handleAddStandaloneTask = () => {
    if (!taskNote.trim()) return;
    setTasks((prev) => [...prev, { type: 'text', note: taskNote.trim(), completed: false }]);
    setTaskNote('');
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const newImageTasks = files.map((file) => ({
      type: 'image',
      image: { file, preview: URL.createObjectURL(file) },
      note: '',
      completed: false,
    }));
    setTasks((prev) => [...prev, ...newImageTasks]);
  };

  const handleNoteChange = (index, value) => {
    setTasks((prev) =>
      prev.map((task, i) => (i === index ? { ...task, note: value } : task))
    );
  };

  const handleRemoveTask = (index) => {
    setTasks((prevTasks) => prevTasks.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedProperty || !selectedSupervisor || !completionWeek) {
      alert('Please fill in all required fields.');
      return;
    }

    setLoading(true);

    try {
      const userDoc = await getDoc(doc(db, 'Users', user.uid));
      let userFullName = 'Unknown';
      if (userDoc.exists()) {
        const userData = userDoc.data();
        userFullName = `${userData.first_name || ''} ${userData.last_name || ''}`.trim();
      }

      const storage = getStorage();
      const uploadedImageTasks = await Promise.all(
        tasks
          .filter((task) => task.type === 'image' && task.image.file)
          .map(async (task, index) => {
            const imageRef = ref(
              storage,
              `tasks/${user.uid}/${Date.now()}_${index}_${task.image.file.name}`
            );
            await uploadBytes(imageRef, task.image.file);
            const url = await getDownloadURL(imageRef);
            return {
              type: 'image',
              url,
              note: task.note,
              completed: false,
            };
          })
      );

      const finalTasks = [
        ...uploadedImageTasks,
        ...tasks.filter((task) => task.type === 'text'),
      ];

      const taskData = {
        propertyName: selectedProperty?.['Property Name'],
        propertyId: selectedProperty?.['Property Name'],
        supervisorId: selectedSupervisor.id,
        supervisorName: `${selectedSupervisor.first_name} ${selectedSupervisor.last_name}`,
        supervisorEmail: selectedSupervisor.email,
        tasks: finalTasks,
        completionWeek,
        createdAt: new Date(),
        managerId: user.uid,
        assignedBy: {
          uid: user.uid,
          name: userFullName,
          email: user.email,
        },
        completed: false,
      };

      await addDoc(collection(db, 'tasks'), taskData);

      setSelectedProperty(null);
      setSupervisorInput('');
      setSelectedSupervisor(null);
      setCompletionWeek('');
      setTasks([]);
      onTaskAdded();
      onClose();
    } catch (error) {
      console.error('Error saving task:', error);
      alert('Failed to save the task.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={(e) => e.target === e.currentTarget && onClose()}>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <h2 style={{ marginBottom: '2rem' }}>Create New Punch List</h2>

        <Form onSubmit={handleSubmit}>
        <div>
  <SuggestionsContainer>
    <label>Property</label>
    <PropertyList onSelect={(property) => setSelectedProperty(property)} />
  </SuggestionsContainer>
</div>


          
          <div style={{ position: 'relative' }}>
            <label>Assign to Supervisor</label>
            <Input
              type="text"
              value={supervisorInput}
              onChange={handleSupervisorInputChange}
              placeholder="Search for a supervisor"
              required
            />
            {supervisorSuggestions.length > 0 && (
              <SuggestionsList>
                {supervisorSuggestions.map((supervisor) => (
                  <SuggestionItem
                    key={supervisor.id}
                    onClick={() => handleSupervisorSelect(supervisor)}
                  >
                    {supervisor.first_name} {supervisor.last_name}
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            )}
          </div>
          <div>
            <label>Due Date</label>
            <Input
              type="date"
              value={completionWeek}
              onChange={(e) => setCompletionWeek(e.target.value)}
              required
            />
          </div>
          <div>
            <h3>Add Punch List Items With No Image</h3>
            <Input
              type="text"
              value={taskNote}
              onChange={(e) => setTaskNote(e.target.value)}
              placeholder="Add Punch List Item Without Image"
            />
            <AddButton type="button" onClick={handleAddStandaloneTask}>
  <FaPlus /> Add Item
</AddButton>
            <div>
              {tasks.map((task, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: '0.5rem',
                    padding: '0.5rem',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                  }}
                >
                  {task.type === 'text' ? (
                    <span>{task.note}</span>
                  ) : (
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      <img
                        src={task.image?.preview || ''}
                        alt={`Task ${index}`}
                        style={{
                          width: '100px',
                          height: '100px',
                          objectFit: 'cover',
                          borderRadius: '4px',
                        }}
                      />
                      <Input
                        type="text"
                        placeholder="Add a note for this image"
                        value={task.note || ''}
                        onChange={(e) => handleNoteChange(index, e.target.value)}
                      />
                    </div>
                  )}
                  <button
                    onClick={() => handleRemoveTask(index)}
                    style={{
                      background: 'red',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      padding: '0.5rem 1rem',
                    }}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3> Add Punch List Items With Images</h3>
            <Input
              id="images"
              type="file"
              accept="image/*"
              multiple
              onChange={handleImageUpload}
            />
          </div>
          <Button type="submit" disabled={loading}>
            {loading ? 'Saving...' : 'Save Punch List'}
          </Button>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AddTaskModal;
