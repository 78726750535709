import axios from 'axios';

const QuickbaseUpdate = async ({ tableId, fields }) => {
  try {
    const payload = {
      to: tableId,
      data: [
        {
          6: { value: fields.applicator }, // Field ID for Applicator
          77: { value: fields.projectName }, // Field ID for Project Name
          10: { value: fields.applicationDate }, // Field ID for Application Date
          83: { value: fields.notes }, // Field ID for Notes
          107: { // Field ID for PDF Attachment
            value: {
              fileName: 'ChemicalApplicationReport.pdf',
              data: fields.pdfUrl,
            },
          },
        },
      ],
    };

    const response = await axios.post('https://api.quickbase.com/v1/records', payload, {
      headers: {
        'QB-Realm-Hostname': process.env.REACT_APP_QB_REALM_HOSTNAME,
        Authorization: `QB-USER-TOKEN ${process.env.REACT_APP_QB_API_KEY}`,
        'Content-Type': 'application/json',
      },
    });

    console.log('Quickbase Update Success:', response.data);
    return response.data;
  } catch (error) {
    console.error('Quickbase Update Error:', error.response?.data || error.message);
    throw new Error(error.response?.data?.message || error.message || 'An unknown error occurred');
  }
};

export default QuickbaseUpdate;
