import React, { useState } from 'react';
import styled from 'styled-components';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';

const TaskListContainer = styled.div`
  margin: 1rem auto;
  max-width: 90%;
  padding: 0 1rem;

  @media (min-width: 768px) {
    max-width: 800px;
    margin: 2rem auto;
  }
`;

const TaskItem = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  @media (max-width: 480px) {
    padding: 0.8rem;
  }
`;

const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const TaskTitle = styled.h4`
  margin: 0;
  font-size: 1.2rem;
  color: #333;
`;

const TaskDetails = styled.div`
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5;
`;

const RemoveButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #d9363e;
  }
`;

const TaskContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`;

const TaskContentItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #ddd;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      transition: transform 0.2s;
    }
  }
`;

const TaskText = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.4;
`;

const CompactTaskViewer = ({ tasks = [], onTaskDeleted }) => {
  const db = getFirestore();
  const [selectedImage, setSelectedImage] = useState(null);

  const handleRemoveTask = async (taskId) => {
    const originalTasks = [...tasks];
    const updatedTasks = tasks.filter((task) => task.id !== taskId);

    if (onTaskDeleted) {
      onTaskDeleted(taskId, updatedTasks);
    }

    try {
      const taskRef = doc(db, 'tasks', taskId);
      await deleteDoc(taskRef);
    } catch (error) {
      console.error('Error removing task:', error);
      alert('Failed to remove task. Reverting changes.');

      if (onTaskDeleted) {
        onTaskDeleted(null, originalTasks);
      }
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImagePreview = () => {
    setSelectedImage(null);
  };

  if (!tasks.length) {
    return <p>No tasks assigned yet.</p>;
  }

  return (
    <TaskListContainer>
      {tasks.map((task) => (
        <TaskItem key={task.id}>
          <TaskHeader>
            <TaskTitle>{task.propertyName || 'Untitled Task'}</TaskTitle>
            <RemoveButton onClick={() => handleRemoveTask(task.id)}>
              Delete Punch List
            </RemoveButton>
          </TaskHeader>

          <TaskDetails>
            <p><strong>Completion Week:</strong> {task.completionWeek || 'No due date specified'}</p>
            <p><strong>Supervisor:</strong> {task.supervisorName || 'No supervisor assigned'}</p>
          </TaskDetails>

          <TaskContentWrapper>
            {task.tasks &&
              task.tasks.map((item, index) => (
                <TaskContentItem key={index}>
                  {item.type === 'image' && item.url ? (
                    <ImageWrapper>
                      <img
                        src={item.url}
                        alt={item.note || `Task Image ${index + 1}`}
                        onClick={() => handleImageClick(item.url)}
                      />
                    </ImageWrapper>
                  ) : null}
                  <TaskText>{item.note || 'No note provided'}</TaskText>
                </TaskContentItem>
              ))}
          </TaskContentWrapper>
        </TaskItem>
      ))}

      {selectedImage && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={closeImagePreview}
        >
          <img
            src={selectedImage}
            alt="Preview"
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              borderRadius: '8px',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.5)',
            }}
          />
        </div>
      )}
    </TaskListContainer>
  );
};

export default CompactTaskViewer;
