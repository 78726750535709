import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { db } from '../Firebase Functions/firebase';
import { AuthContext } from '../hooks/AuthProvider';
import './AdminJobList.css';



function AdminJobList() {
    const navigate = useNavigate();
    const { currentUser: user, loading: authLoading } = useContext(AuthContext);
    const [jobsData, setJobsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modalImage, setModalImage] = useState(null);
    const itemsPerPage = 10; // Number of jobs per page
    const imageCacheRef = useRef({});

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Services'));
                const jobsDataArray = [];
                const storage = getStorage();
    
                for (const document of querySnapshot.docs) {
                    const jobData = document.data();
                    const servicesArray = Array.isArray(jobData.services)
                        ? jobData.services
                        : typeof jobData.services === 'string'
                        ? jobData.services.split(',').map((s) => s.trim())
                        : [];
    
                    const imageUrls = await fetchCachedImageUrls(document.id, jobData.imageUrls || {});
    
                    jobsDataArray.push({
                        id: document.id,
                        ...jobData,
                        property: jobData.property || 'Unnamed Property',
                        services: servicesArray,
                        imageUrls,
                    });
                }
    
                // Sort jobs by date (newest first)
                jobsDataArray.sort((a, b) => {
                    const dateA = new Date(a.date);
                    const dateB = new Date(b.date);
                    return dateB - dateA; // Descending order
                });
    
                setJobsData(jobsDataArray);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false);
            }
        };
    
        if (!authLoading) {
            fetchJobs();
        }
    }, [authLoading]);
    

    const fetchCachedImageUrls = async (jobId, currentImageUrls) => {
        const storage = getStorage();
        const freshImageUrls = { ...currentImageUrls };

        for (const [key, oldUrl] of Object.entries(currentImageUrls)) {
            if (imageCacheRef.current[jobId]?.[key]) {
                freshImageUrls[key] = imageCacheRef.current[jobId][key];
                continue;
            }

            if (typeof oldUrl === 'string' && oldUrl) {
                try {
                    const path = decodeURIComponent(
                        oldUrl.split('/o/')[1].split('?')[0].replace(/%2F/g, '/')
                    );
                    const imageRef = ref(storage, path);
                    const freshUrl = await getDownloadURL(imageRef);

                    if (!imageCacheRef.current[jobId]) {
                        imageCacheRef.current[jobId] = {};
                    }
                    imageCacheRef.current[jobId][key] = freshUrl;
                    freshImageUrls[key] = freshUrl;
                } catch (error) {
                    console.error(`Error fetching URL for ${key}:`, error);
                    freshImageUrls[key] = oldUrl;
                }
            } else {
                freshImageUrls[key] = oldUrl;
            }
        }

        return freshImageUrls;
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const openModal = (imageUrl) => {
        setModalImage(imageUrl);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    const filteredJobsData = jobsData.filter((jobData) => {
        const searchTerms = searchQuery.split(' ').filter((term) => term);
        return (
            searchTerms.length === 0 ||
            searchTerms.every(
                (term) =>
                    jobData.property.toLowerCase().includes(term) ||
                    jobData.services.some((service) => service.toLowerCase().includes(term)) ||
                    (jobData.date && jobData.date.toLowerCase().includes(term))
            )
        );
    });

    const totalPages = Math.ceil(filteredJobsData.length / itemsPerPage);
    const paginatedJobs = filteredJobsData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    if (authLoading) {
        return <div>Loading authentication...</div>;
    }

    if (!user) {
        return <div>Please log in to view jobs</div>;
    }

    if (loading) {
        return <div>Loading jobs...</div>;
    }

    return (
        <div className="job-list-container">
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search by property, service, or date..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value.toLowerCase())}
                    className="search-input"
                />
            </div>

            <div className="job-grid">
                {paginatedJobs.map((jobData) => (
                    <div key={jobData.id} className="job-card">
                        <div className="job-header">
                            <h3>{jobData.property}</h3>
                            <p>{jobData.supervisorName}</p>
                            <p className="date">{jobData.date}</p>
                        </div>

                        {jobData.imageUrls && Object.entries(jobData.imageUrls).length > 0 && (
                            <div className="image-grid">
                                {Object.entries(jobData.imageUrls).map(([key, url]) => (
                                    url && (
                                        <div key={key} className="image-container">
                                            <img
                                                src={url}
                                                alt={`${key} view`}
                                                onClick={() => openModal(url)} // Open modal on click
                                            />
                                        </div>
                                    )
                                ))}
                            </div>
                        )}

                        <div className="services-section">
                            <h4>Services:</h4>
                            <ul>
                                {jobData.services.map((service, index) => (
                                    <li key={index}>{service}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="button-container">
                            <button onClick={() => navigate(`/jobs/${jobData.id}`)}>Vieew Details</button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="pagination">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            {modalImage && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <img src={modalImage} alt="Enlarged view" />
                        <button onClick={closeModal} className="close-modal">×</button>

                    </div>
                </div>
            )}
        </div>
    );
}

export default AdminJobList;
