import React, { createContext, useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase Functions/firebase';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const savedUser = localStorage.getItem('currentUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('isLoggedIn'));
  const [isAdmin, setIsAdmin] = useState(() => {
    return JSON.parse(localStorage.getItem('isAdmin')) || false;
  });
  const [loading, setLoading] = useState(true);

  // `handleLogin` to manually update the user state and localStorage
  const handleLogin = (user) => {
    setCurrentUser(user);
    setIsLoggedIn(true);
    setIsAdmin(user?.isAdmin || false);
    localStorage.setItem('currentUser', JSON.stringify(user));
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('isAdmin', JSON.stringify(user?.isAdmin || false));
  };

  // `handleLogout` to clear user state and localStorage
  const handleLogout = () => {
    const auth = getAuth();
    auth.signOut();
    setCurrentUser(null);
    setIsLoggedIn(false);
    setIsAdmin(false);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('isAdmin');
  };

  // Listen for Firebase Auth state changes
  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        try {
          // Fetch additional user details from Firestore
          const userDoc = await getDoc(doc(db, 'Users', authUser.uid));
          const userData = userDoc.exists() ? userDoc.data() : {};

          const user = {
            uid: authUser.uid,
            email: authUser.email,
            displayName: authUser.displayName || `${userData.first_name || ''} ${userData.last_name || ''}`.trim(),
            ...userData,
          };

          // Set user state and localStorage
          setCurrentUser(user);
          setIsLoggedIn(true);
          setIsAdmin(userData.isAdmin || user.email === 'admin@example.com'); // Example admin check
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('isAdmin', JSON.stringify(userData.isAdmin || false));
        } catch (error) {
          console.error('Error fetching user data from Firestore:', error);
        }
      } else {
        // Handle logout
        handleLogout();
      }
      setLoading(false);
    });

    return unsubscribe; // Cleanup subscription
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, isLoggedIn, isAdmin, handleLogin, handleLogout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
